<template>
  <div class="container">
    <van-loading class="loading" color="#9d1d22" text-color="#9d1d22" v-if="loading" size="24px">加载中...</van-loading>
    <div v-if="loading == false">
      <!-- <van-nav-bar left-arrow title="编辑资料" right-text="保存"  @click-right="saveUser" @click-left="$router.back()"></van-nav-bar> -->
      <van-nav-bar title="我的工作台" left-arrow @click-left="$router.back()">
        <template #right>
          <div @click="toadd()" v-show="!showAdd">
            <van-icon name="plus" size="16" />
            <div style="float: right; margin-left: 5px; font-size: 15px">
              新增
            </div>
          </div>
        </template>
      </van-nav-bar>

      <div v-if="showAdd">
        <van-cell-group>
          <van-field @blur="checka3User" label="A3工号" size="large" v-model="user.a3User" placeholder="请输入A3工号" type="text"
            required :error-message="errMes.a3User">
          </van-field>
          <van-field @blur="checka3Pass" label="A3密码" size="large" v-model="user.a3Pass" placeholder="请输入A3密码"
            type="password" required :error-message="errMes.a3Pass">
          </van-field>
          <van-field label="工号备注" size="large" v-model="user.remark" placeholder="请输入工号备注,选填" type="text"
            :error-message="errMes.remark">
          </van-field>

          <van-field name="checkbox" label="默认工号">
            <template #input>
              <van-checkbox v-model="user.isDefault" shape="square" />
            </template>
          </van-field>
        </van-cell-group>
        <div class="btn-box">
          <van-button type="info" size="large" round block @click="BindA3Handle">确认绑定</van-button>
        </div>
      </div>

      <div v-show="!showAdd">
        <van-cell-group>
          <van-swipe-cell v-for="item in HasBindList" :key="item.id">
            <van-cell>
              <van-col span="3">
                <van-tag color="#9d1d22" v-if="item.isDefault"> 默认</van-tag>
              </van-col>
              <van-col span="10">
                <h3 style="display: inline-block">{{ item.a3User }}</h3>
              </van-col>
              <van-col span="11" align="bottom">
                <div style="display: table-cell; vertical-align: middle" class="zntitle van-multi-ellipsis--l3">
                  {{ item.remark }}
                </div>
              </van-col>
            </van-cell>
            <template #right>
              <van-button square text="删除" type="danger" class="delete-button" @click="DeleteBindHandle(item)" />
              <van-button v-if="!item.isDefault" @click="SetDefaultHandle(item)" square type="primary" text="默认" />
            </template>
          </van-swipe-cell>
        </van-cell-group>

        <div class="NavTitle" v-if="A3Token != ''">
          <h3>使用默认A3工号登录系统</h3>
        </div>

        <div class="NavTitle" v-if="A3Token == '' && HasBindList.length != 0">
          <h3>
            请<span style="color: red; margin: 0 5px"> 左滑 </span>绑定的A3工号，设置默认。设置默认后，跳转系统，不用再输入A3账号密码
          </h3>
        </div>

        <div class="NavTitle" v-if="HasBindList.length == 0">
          <h3>
            请<span style="color: red; margin: 0 5px"> 新增 </span>
            绑定A3工号，点击跳转到指定系统
          </h3>
        </div>
        <van-divider />

        <div v-if="!isLAN">
          <van-cell v-for="(nav, index) in NavItmes" :key="index" v-bind:text="nav.title" @click="navClick(nav)" is-link>
            <van-col span="24">
              <div style="font-size: 14px">
                {{ nav.title }}
              </div>
            </van-col>
          </van-cell>
        </div>
        <div v-if="isLAN">
          <van-cell v-for="(nav, index) in LANNavItmes" :key="index" v-bind:text="nav.title" @click="navClick(nav)"
            is-link>
            <van-col span="24">
              <div style="font-size: 14px">
                {{ nav.title }}
              </div>
            </van-col>
          </van-cell>
        </div>
        <!-- <van-grid :gutter="5" :column-num="2" :border="false">
          <van-grid-item
            v-for="(nav, index) in NavItmes"
            :key="index"
            v-bind:text="nav.title"
            @click="navClick(nav)"
          >
            <van-tag round size="large" color="#9d1d22"
              ><h3>{{ nav.title }}</h3></van-tag
            >
          </van-grid-item>
        </van-grid> -->

        <div class="NavTitle">
          <van-cell to="/ad" is-link>
            <h3 style="text-align: center; margin-left: 24px">媒体广告</h3>
          </van-cell>
        </div>

        <van-divider />
        <!--  <van-grid :gutter="10" :column-num="2" :border="false">
          <van-grid-item
            v-for="(nav, index) in ShopingItmes"
            :key="index"
            v-bind:text="nav.title"
            @click="navClick(nav)"
          >
            <van-tag round size="large" color="#9d1d22"
              ><h3>{{ nav.title }}</h3></van-tag
            >
          </van-grid-item>
        </van-grid> -->

        <div class="NavTitle">
          <h3>常用链接</h3>
        </div>

        <van-divider />
        <div v-if="!isLAN">
          <van-cell v-for="(nav, index) in CommonItmes" :key="index" v-bind:text="nav.title" @click="navClick(nav)"
            is-link>
            <van-col span="24">
              <div style="font-size: 14px">
                {{ nav.title }}
              </div>
            </van-col>
          </van-cell>
        </div>
        <div v-if="isLAN">
          <van-cell v-for="(nav, index) in LANCommonItmes" :key="index" v-bind:text="nav.title" @click="navClick(nav)"
            is-link>
            <van-col span="24">
              <div style="font-size: 14px">
                {{ nav.title }}
              </div>
            </van-col>
          </van-cell>
        </div>

        <div class="NavTitle">
          <h3>小程序</h3>
        </div>

        <van-divider />

        <van-cell v-for="(nav, index) in MiniProgramItmes" :key="index" v-bind:text="nav.title" @click="navminiClick(nav)"
          is-link>
          <van-col span="24">
            <div style="font-size: 14px">
              {{ nav.title }}
            </div>
          </van-col>
        </van-cell>

        <!-- <van-grid :gutter="10" :column-num="2" :border="false">
          <van-grid-item
            v-for="(nav, index) in CommonItmes"
            :key="index"
            v-bind:text="nav.title"
            @click="navClick(nav)"
          >
            <van-tag round size="large" color="#9d1d22"
              ><h3>{{ nav.title }}</h3></van-tag
            >
          </van-grid-item>
        </van-grid> -->
      </div>
    </div>
  </div>
</template>

<script>
//import { changePassWord } from "@/api/user";
import {
  BindA3UserInfo,
  GetA3UserInfo,
  GetA3DefaultUserInfo,
  DeleteA3UserInfo,
  SetDefault,
} from "@/api/UserToA3";
import { mapMutations } from "vuex";
import { Toast } from "vant";
import store from "@/store";

export default {
  name: "user-BindA3",
  data() {
    return {
      loading: true,
      showAdd: false,
      A3Token: "",
      isLAN: true, //是否是局域网
      HasBindList: [],
      user: {
        id: 0,
        a3User: "",
        a3Pass: "",
        remark: "",
        isDefault: true,
      },
      errMes: {
        a3User: "",
        a3Pass: "",
        remark: "",
      },
      NavItmes: [
        //https://ynscrm.bjznpz.com/home/login
        {
          title: "线上A3、报表系统、转介绍、名酒会系统已合并到云酿SCRM，建议从KM首页进入，该入口即将删除",
          link: "https://ynscrm.bjznpz.com/home/login",
          hastoken: true,
          a3codetoken: false,
        },
        // {
        //   title: "线上A3",
        //   link: "https://kf.znpz.com.cn:44384/default/Login",
        //   hastoken: true,
        //   a3codetoken: false,
        // },
        {
          title: "报表系统",
          link: "https://kf.znpz.com.cn:44381/login.aspx", //"http://zn.znpz.com.cn:85/login.aspx",
          hastoken: true,
          a3codetoken: true,
        },
        // {
        //   title: "转介绍",
        //   link: "https://kf.znpz.com.cn:44385/default/Login", //"http://zn.znpz.com.cn:85/login.aspx",
        //   hastoken: true,
        //   a3codetoken: false,
        // },
        // {
        //   title: "名酒会系统",
        //   link: "https://kf.znpz.com.cn:44383/default/Login", // "http://zn.znpz.com.cn:82/Default/login",//http://zn.znpz.com.cn:89/login.aspx
        //   hastoken: true,
        //   a3codetoken: false,
        // },
        {
          title: "品牌CRM",
          link: "https://kf.znpz.com.cn/home/login",
          hastoken: true,
          a3codetoken: false,
        },
        {
          title: "营销推广(调研问卷)",
          link: "/question",
          hastoken: false,
        },
        {
          title: "顺丰未签收",
          link: "/sfexpress",
          hastoken: false,
        },
        // {
        //   title: "抽奖活动",
        //   link: "/questiontype?typeid=8",
        //   hastoken: false,
        // },
      ],

      LANNavItmes: [
        {
          title: "线上A3、报表系统、转介绍、名酒会系统已合并到云酿SCRM，建议从KM首页进入，该入口即将删除",
          link: "https://ynscrm.bjznpz.com/home/login",
          hastoken: true,
          a3codetoken: false,
        },
        // {
        //   title: "线上A3(内网)",
        //   link: "http://192.168.3.220:84/Default/Login",
        //   hastoken: true,
        //   a3codetoken: false,
        // },
        {
          title: "报表系统(内网)",
          link: "http://192.168.3.220:81/Login.aspx",
          hastoken: true,
          a3codetoken: true,
        },
        // {
        //   title: "转介绍(内网)",
        //   link: "http://192.168.3.220:85/Default/login",
        //   hastoken: true,
        //   a3codetoken: false,
        // },
        // {
        //   title: "名酒会系统(内网)",
        //   link: "http://192.168.3.220:83/Default/login",
        //   hastoken: true,
        //   a3codetoken: false,
        // },
        {
          title: "品牌CRM（内网）",
          link: "http://192.168.3.220:11001/home/login",
          hastoken: true,
          a3codetoken: false,
        },
        {
          title: "调研问卷",
          link: "/question",
          hastoken: false,
        },
        {
          title: "顺丰未签收",
          link: "/sfexpress",
          hastoken: false,
        },
        // {
        //   title: "抽奖活动",
        //   link: "/questiontype?typeid=8",
        //   hastoken: false,
        // },
      ],

      CommonItmes: [
        { title: "公司官网", link: "http://www.znpz.com.cn" },
        { title: "金蝶HR", link: "http://182.48.111.205:8080/shr" },
        { title: "发票系统", link: "http://182.48.111.200:8089/Invoice/Login" },
        {
          title: "企业邮箱",
          link: "https://m.exmail.qq.com/cgi-bin/loginpage",
        },
      ],
      LANCommonItmes: [
        { title: "金蝶HR（内网）", link: "http://192.168.3.225:8080/shr" },
        {
          title: "发票系统（内网）",
          link: "http://192.168.3.220:8089/Invoice/login",
        },
      ],

      MiniProgramItmes: [{ title: "中酿名酒会", link: "" }],
    };
  },
  async created() {
    await this.GetHasBindA3List();
    this.loading = false;
    this.CheckNetwork();
    //判断内外网

    //  imgurl = imgurl + '?v=' + Math.random(); // 仅是为了区分下不同的图片链接
    //     return new Promise((resolve, reject) => {
    //         const img = new Image();
    //         img.src = imgurl;
    //         img.onload = () => resolve(imgurl);
    //         img.onerror = () => reject(new Error(imgurl + ' load error'));
    //  })

    // var timeStr = setInterval(function(){
    // 	var $img = $('<img src="http://10.**.**.**:*****/uploads/system/user/avatar/30/avatar.png?'+(new Date())+'">')
    // 	$img.appendTo('body').css("display","none").load(function(){
    // 		console.log("连接成功！")
    // 		$(this).remove()
    // 	}).error(function(){
    // 		console.log("断网了！")
    // 		$(this).remove()
    // 	})
    // },2000)
  },
  methods: {
    ...mapMutations(["clearUser"]),

    CheckNetwork() {
      let imgurl = "https://www.baidu.com/favicon.ico" + "?v=" + Math.random(); // 仅是为了区分下不同的图片链接
      let p1 = new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imgurl;
        img.onload = () => resolve(imgurl);
        img.onerror = () => reject(new Error(imgurl + " load error"));
      });

      let that = this;

      p1.then(function () {
        that.isLAN = false;
        Toast("您是外网环境，已切到外网地址");
      }).catch(() => {
        that.isLAN = true;
        Toast("您是可能内网环境，已切到内网地址");
      });
    },

    checka3User() {
      if (!this.user.a3User) {
        this.errMes.a3User = "A3工号不能为空";
        return false;
      }
      this.errMes.a3User = "";
      return true;
    },
    checka3Pass() {
      if (!this.user.a3Pass) {
        this.errMes.a3Pass = "A3密码不可为空";
        return false;
      }
      this.errMes.a3Pass = "";
      return true;
    },

    // 保存用户编辑
    async BindA3Handle() {
      if (!this.checka3User() || !this.checka3Pass()) {
        return false;
      }

      try {
        const data = await BindA3UserInfo(this.user);
        if (data.data.success) {
          Toast("绑定成功");
          this.GetHasBindA3List();
          this.user.a3User = "";
          this.user.a3Pass = "";
          this.user.remark = "";
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        console.log(error);
        Toast("保存失败：" + error);
      }
    },
    async GetHasBindA3List() {
      await this.GetA3DefaultUserInfoHandle();
      const data = await GetA3UserInfo();
      if (data.data.success) {
        this.HasBindList = data.data.data;
        if (this.HasBindList.length > 0) {
          this.showAdd = false;
        } else {
          this.showAdd = false;
        }
      } else {
        Toast(data.data.errorMessage);
      }
    },

    async GetA3DefaultUserInfoHandle() {
      const data = await GetA3DefaultUserInfo();
      if (data.data.success) {
        this.A3Token = data.data.data.userToken;
      } else {
        Toast(data.data.errorMessage);
      }
    },

    async DeleteBindHandle(item) {
      await this.$dialog.confirm({
        title: "提示",
        message: "确定要删除A3绑定吗",
      });

      const data = await DeleteA3UserInfo(item);
      if (data.data.success) {
        Toast("删除绑定成功");
        //this.$router.back();
        this.GetHasBindA3List();
      } else {
        Toast(data.data.errorMessage);
      }
    },
    async SetDefaultHandle(item) {
      const data = await SetDefault(item);
      if (data.data.success) {
        Toast("默认设置成功");
        //this.$router.back();
        this.GetHasBindA3List();
      } else {
        Toast(data.data.errorMessage);
      }
    },
    toadd() {
      this.showAdd = true;
    },
    toEdite(item) {
      this.user.a3User = item.a3User;
      this.user.a3Pass = item.a3User;
      this.user.remark = item.a3User;
      this.user.isDefault = item.isDefault;
    },

    navClick(nav) {
      if (nav.link == undefined || nav.link == "") {
        Toast(nav.title + " 系统对接开发中，敬请期待");
      } else if (nav.link.indexOf("http") < 0) {
        this.$router.push({
          path: nav.link,
        });
      } else {
        if (nav.hastoken) {
          if (nav.a3codetoken) {
            window.location.href = nav.link + "?token=" + this.A3Token;
          } else {
            window.location.href =
              nav.link + "?token=" + store.state.user.token;
          }
        } else {
          //如果是金蝶shr系统 需要去除referer 参考:https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Referrer-Policy
          if (nav.link.indexOf("shr") > 0) {
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = nav.link;
            link.rel = "noreferrer";
            link.click();
          } else {
            window.location.href = nav.link;
          }
        }
      }
    },
    navminiClick(nav) {
      Toast("暂不支持跳转，请在微信中搜索:" + nav.title);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.loading {
  text-align: center;
}

.NavTitle {
  margin-top: 20px;
  text-align: center;
}

.btn-box {
  padding: 20px;
}
</style>
