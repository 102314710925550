import request from '@/utils/request'
export function BindA3UserInfo (data) {
  return request({
    url: '/api/UserToA3/BindA3UserInfo',
    data,
    method: 'post'
  })
}

export function GetA3UserInfo () {
    return request({
      url: '/api/UserToA3/GetA3UserInfo'
    })
  }

  //GetA3UserInfo  /api/UserToA3/GetA3DefaultUserInfo

export function GetA3DefaultUserInfo () {
    return request({
      url: '/api/UserToA3/GetA3DefaultUserInfo'
    })
}


export function DeleteA3UserInfo (data) {
    return request({
      url: '/api/UserToA3/DeleteA3UserInfo',
      data,
      method: 'post'
    })
  }

  export function SetDefault (data) {
    return request({
      url: '/api/UserToA3/SetDefault',
      data,
      method: 'post'
    })
  }